
.card {
    height: auto !important;
}

@media (min-width: 64rem) {
    [data-sidebar-position="fixed"][data-layout="horizontal"] .dlabnav.fixed {
        padding: 0 0 !important;
    }
}


@media (min-width: 1200px) {
    .modal-xxl {
        max-width: 90% !important;
    }
}


.table-finance tr td {
    padding: 5px 5px;
    line-height: 1;
    font-size: 13px;
}